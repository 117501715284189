var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'nav-bar__item', 
    {
      'nav-bar__item_seperator' : _vm.item.hasSeperator,
      'nav-bar__item_disable': _vm.item.disable
    },
  ],style:(_vm.styles),attrs:{"tabindex":"0","aria-label":_vm.item.text,"role":"tab"},on:{"click":_vm.onClick,"focus":function($event){return _vm.$emit('navFocus', $event)}}},[_c('span',{staticClass:"nav-bar__item_text",class:{
      'nav-bar__item_highlight' : (_vm.item.hasCountBadge ? _vm.item.panelContentSelected : (_vm.item.panelContentSelected || _vm.item.active)),
      'nav-bar__item_gap': _vm.item.icons
    },style:(_vm.item.styles)},[_vm._v("\n    "+_vm._s(_vm.item.text)+"\n\n    "),_vm._v(" "),(_vm.item.icons)?[_c('i',{class:[
          (_vm.constantData.is_include_critical_css && !_vm.item.active) ? (_vm.item.icons.isSuiIcon ? 'suiiconfont-critical' : 'iconfont-critical') : (_vm.item.icons.isSuiIcon ? 'suiiconfont' : 'iconfont'),
          _vm.item.active ? (_vm.item.icons.active || _vm.item.icons.normal) : _vm.item.icons.normal
        ]}),_vm._v(" "),(_vm.item.hasCountBadge && _vm.selectedNum)?_c('span',{staticClass:"nav-bar__item_badge"},[_vm._v("\n        "+_vm._s(_vm.selectedNum)+"\n      ")]):_vm._e()]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }