/**
 * 筛选排序外露-sort交互逻辑优化
 * wiki.pageId=1163592804
 */


export function handleNavConfOfSort(sortConf, sortSelectConf) {
  const { label = '', label_en = '' } = sortSelectConf || {}
  sortConf.label = label
  sortConf.label_en = label_en
}

function handleClickSort(compIns) {
  // 从SelectMenu找clickMenu
  const selectMenuComps = findComp(compIns, 'SelectMenu')
  if (selectMenuComps.length) {
    const comp = selectMenuComps[0]
    comp?.clickMenu?.(comp.menuList[0], 0)
  }
}

function isClickSort(activeIndex) {
  return activeIndex === 0
}


function isActiveSelect(
  isExportFilterSort,
  activeIndex,
  panelContentSelected
) {
  return (
    isExportFilterSort 
    && isClickSort(activeIndex) 
    && !panelContentSelected
  )
}

// 命中ExportFilterSort 非选中态点击后直接选中第一项｜选中态点击后展开下拉选项
export function handleClickExportFilterSort({
  isExportFilterSort,
  activeIndex,
  panelContentSelected,
  curPanelIns,
  curComIns
}) {
  if (!isActiveSelect(
    isExportFilterSort,
    activeIndex, 
    panelContentSelected
  )) {
    curPanelIns.toggle()
    return
  }
  handleClickSort(curPanelIns)
  translateSwiper(curComIns)
}

export function translateSwiper(compIns, distance = 0, transition = 300) {
  // NOTE: 找NavBar
  const comps = findComp(compIns, 'NavBar')
  if (comps.length) {
    const comp = comps[0]
    comp?.swiper?.setTransition?.(transition)
    comp?.swiper?.setTranslate?.(distance)
  }
}

function findComp(compIns, compName, deep = 0 ) {
  const ret = []
  if (!(compIns instanceof window?.Vue)) return ret
  if (compIns.$options.name === compName) ret.push(compIns)
  if (compIns.$children.length) {
    compIns.$children.forEach(child => {
      ret.push(...findComp(child, compName, deep + 1))
    })
  }

  if (deep === 0 && ret.length === 0) console.warn(`${compName} Component not found, please check !`)

  return ret
}

