<template>
  <div
    :class="[
      'nav-bar__item', 
      {
        'nav-bar__item_seperator' : item.hasSeperator,
        'nav-bar__item_disable': item.disable
      },
    ]"
    :style="styles"
    tabindex="0"
    :aria-label="item.text"
    role="tab"
    @click="onClick"
    @focus="$emit('navFocus', $event)"
  >
    <span
      class="nav-bar__item_text"
      :class="{
        'nav-bar__item_highlight' : (item.hasCountBadge ? item.panelContentSelected : (item.panelContentSelected || item.active)),
        'nav-bar__item_gap': item.icons
      }"
      :style="item.styles"
    >
      {{ item.text }}

      <!-- icon配置 -->
      <template v-if="item.icons">
        <i
          :class="[
            (constantData.is_include_critical_css && !item.active) ? (item.icons.isSuiIcon ? 'suiiconfont-critical' : 'iconfont-critical') : (item.icons.isSuiIcon ? 'suiiconfont' : 'iconfont'),
            item.active ? (item.icons.active || item.icons.normal) : item.icons.normal
          ]"
        ></i>
        <span
          v-if="item.hasCountBadge && selectedNum"
          class="nav-bar__item_badge"
        >
          {{ selectedNum }}
        </span>
      </template>
    </span>
  </div>
</template>

<script>

export default {
  name: 'NavBarSide',
  inject: ['constantData'],
  props: {
    // 选中的筛选数量
    item: {
      type: Object,
      default: () => ({})
    },
    // 选中的筛选数量
    selectedNum: {
      type: Number,
      default: 0
    },
    // 样式集合
    styles: {
      type: Object,
      default: () => ({
        navItem: {},
        navBar: {}
      })
    }
  },
  methods: {
    onClick () {
      const item = this.item
      if (item.disable) return
      // 激活高亮和icon
      this.$set(item, 'active', false)
      this.$emit('click', { ...item })
    },
  }
}
</script>
