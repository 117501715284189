// export const isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
// 事件透传
export const emitEvent = (vueComponent, eventName, payload) => {
  if (vueComponent) {
    if (vueComponent._events?.[eventName]) {
      vueComponent.$emit(eventName, payload)
    } else {
      emitEvent(vueComponent.$parent, eventName, payload)
    }
  }
}

/**
 * 点击标签自动吸顶
 * @param {*} el    当前吸顶元素
 * @param {*} className  当前吸顶元素的class
 * @param {*} top 距离顶部吸顶距离 
 * @param {*} diff 最后滚动距离加上diff【图文吸顶后需要小图】
 * @param {*} delay 延迟时间【图文吸顶显示小图，动画过度时间300ms】
 * @returns 
 */
export const setAutoSticky = ({ el, className, top, diff = 0, delay = 0 }) => {
  const dom = el || document.querySelector(className)
  if (!dom) return Promise.resolve(false)

  // 页面高度不足时，不需要吸顶
  const listRect = document.querySelector('#app').getBoundingClientRect()
  if (document.body.clientHeight > (Math.abs(listRect.top) + listRect.height)) return Promise.resolve(false)

  const rect = dom.getBoundingClientRect()
  let scrollTop = Math.floor(rect.top) - top
  if (!rect.height || scrollTop <= 1) return Promise.resolve(false)

  scrollTop += diff + (document.body.scrollTop || document.documentElement.scrollTop)

  window.scrollTo(0, scrollTop)
  
  // 循环调用，更新panel位置
  const start = Date.now()
  const loop = () => requestAnimationFrame(() => { 
    if (Date.now() - start > delay) return
    appEventCenter.$emit('updatePanelTop')
    loop()
  })

  loop() 

  return new Promise(resolve => {
    const timer = setTimeout(() => {
      clearTimeout(timer)
      resolve(true)
    }, delay) 
  })
}
