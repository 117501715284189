<template>
  <div
    id="filter-ctn"
    :class="['filter-ctn', { 'filter-ctn__scrollTop': isScrollHide }]"
    :style="containerStyle"
  >
    <NavBar
      ref="navBar"
      :nav-config="navConfig"
      :nav-bar-config="navBarConfig"
      :styles="styles"
      :selected-num="isProductList ? filterNum : filterBadgeNum"
      :bottom-border="bottomBorder"
      :reload.sync="reload"
      :language="language"
      :view-type="viewType"
      @onChange="clickBar"
      @quickSearch="quickSearch"
      @navFocus="navFocus"
      @clickSort="(payload) => $emit('clickSort', payload)"
    />    

    <slot></slot>
  </div>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import NavBar from './components/NavBar'
import filterAda from './utils/filterAda'
import { handleClickExportFilterSort } from '../../../pages/product_list_v2/js/exportFilterSort'

daEventCenter.addSubscriber({ modulecode: '2-17' })

export default {
  name: 'SFilter',
  components: {
    NavBar,
  },
  provide () {
    return {
      constantData: this.constantData
    }
  },
  props: {
    // 头部导航配置
    navConfig: {
      type: Array,
      default: () => []
    },
    navBarConfig: {
      type: Object,
      default: () => ({})
    },
    /**
     * 样式集合
     * leftPanel 左侧全屏panel
     * downPanel 下拉panel
     */
    styles: {
      type: Object,
      default: () => ({
        container: {},
        navBar: {},
        navItem: {},
        downPanel: {},
        fullPanel: {},
        downMask: {},
        downPanelContent: {},
        fullPanelContent: {}
      })
    },
    // 选中高亮
    keepHighlight: {
      type: Boolean,
      default: true
    },
    // 是否是当前页面路由跳转
    curPageRefresh: {
      type: Boolean,
      default: false
    },
    // 吸顶距离
    ceil: {
      type: [String, Number],
      default: ''
    },
    // navBar下边框配置
    bottomBorder: {
      type: Boolean,
      default: true
    },
    language: {
      type: Object,
      default: () => ({})
    },
    constantData: {
      type: Object,
      default: () => ({})
    },
    filterBarPopupsVisible: {
      type: Boolean,
      default: true,
    },
    isRenderingPanel: {
      type: Boolean,
      default: false,
    },
    isScrollHide: {
      type: Boolean,
      default: false
    },
    filterNum: {
      type: Number,
      default: 0,
    },
    // 是否是泛列表, 泛列表有特殊的逻辑
    isProductList: {
      type: Boolean,
      default: false
    },
    viewType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      filterBadgeNum: 0,
      reload: false,
      navBarFocus: false, // 光标是否在当前元素上
    }
  },
  computed: {
    containerStyle () {
      return { ...( this.ceil || this.ceil === 0 ? { position: ['-webkit-sticky', 'sticky'], top: this.ceil + 'px' } : {} ), ...this.styles.container }
    },
  },
  watch: {
    $route () {
      if (!this.curPageRefresh) {
        this.hideAllPanel()
      }
    },
  },
  mounted () {
    window.$filter = this
    this.initEvent()
    if (this.filterBarPopupsVisible) {
      this.initPanelData()
    }
  },
  methods: {
    // 初始化子组件的事件
    initEvent () {
      this.$on('clickOverlay', this.clickOverlay)
      this.$on('clickMenuItem', this.clickMenuItem)
      this.$on('clickDone', this.clickDone)
      this.$on('singleSelectChange', this.singleSelectChange)
      this.$on('multiSelectChange', this.multiSelectChange)
      this.$on('clickSideFilterCloseIcon', this.clickSideFilterCloseIcon)
      this.$on('sidePanelChange', this.sidePanelChange)
      this.$on('resetAll', this.resetAll)
      this.$on('recordSelectMenu', this.recordSelectMenu)
      this.$on('setFilterBadgeNum', this.setFilterBadgeNum)
      this.$on('needHighlightNavBar', this.needHighlightNavBar)
      // this.$on('priceSlideChange', this.priceSlideChange)

      if (typeof window !== 'undefined') {
        appEventCenter.$on('hidePanel', this.hideAllPanel)
      }
    },
    // 初始化panel数据，记录panel的index
    initPanelData () {
      this.reload = true
      const panelSlots = this.$slots?.default?.filter(_=>_.componentInstance) || []
      panelSlots.forEach((panel, index) => {
        panel.componentInstance?.recordIndex(index)
      })
    },

    /* 监听是否开启了辅助功能 */ 
    navFocus(e = {}) {
      if (!this.navBarFocus) {  // ADA模式
        this.navBarFocus = true
      }

      if (filterAda.isIosDevice()) {   // ios 系统
        if (e.target.className.indexOf('nav-bar__item_seperator') > -1) {
          this.$emit('spreadFilterItem')  // 光标在侧边filter。 里面元素所有展开
          filterAda.removeAllItemTabindex()
        }
      }
    },

    // 选择筛选项
    filterChange (payload) {
      this.$emit('onChange', payload)
    },
    // 点击筛选bar
    clickBar (payload) {
      this.$emit('wishlistfilterType', payload?.text)
      if (this.isRenderingPanel) {
        return
      }
      if (this.filterBarPopupsVisible) {
        this.onPanelMounted(payload)
      } else {
        this.$emit('renderPanel', payload)
      }
    },
    onPanelMounted(payload) {
      const { activeIndex, panelContentSelected } = payload
      const panelSlots = this.$slots?.default?.filter(_=>_.componentInstance) || []
      // 关闭标签云属性标签面板
      this.$emit('closeCloudTagPanel')
      // 控制panel展开收起
      panelSlots.forEach((panel, index) => {
        if (activeIndex !== index) {
          panel.componentInstance.show && panel.componentInstance.close()
        }
      })
      const curPanelIns = panelSlots[activeIndex]?.componentInstance

      // 命中ExportFilterSort 非选中态点击后直接选中第一项｜选中态点击后展开下拉选项
      handleClickExportFilterSort({
        isExportFilterSort: this.navBarConfig.isExportFilterSort,
        activeIndex,
        panelContentSelected,
        curPanelIns,
        curComIns: this
      })

      const { show } = curPanelIns

      this.$emit('clickBar', { ...payload, show })

      if (!show) {
        this.$emit('closePanel', { target: 'navBar' })
      }

      this.handleAda(panelSlots[activeIndex], activeIndex)
    },
    // ada  展开后给元素 设置tabindex
    handleAda(node, index) {
      if (!node.componentInstance.show) return // 关闭状态
      filterAda.open(node, index, this.navBarFocus, () => { // 焦点聚焦到父级
        this.$refs.navBar.$el?.children?.[index]?.focus() 
      }) 
    },

    // 点击快速筛选
    quickSearch (payload) {
      this.filterChange(payload)
    },
    // 隐藏panel
    hideAllPanel () {
      this.resetArrow()
      const panelSlots = this.$slots?.default?.filter(_=>_.componentInstance) || []
      panelSlots.forEach((panel) => {
        panel?.componentInstance?.close()
      })
    },
    // 点击黑色透明遮罩层
    clickOverlay () {
      this.resetArrow()
      this.$emit('closePanel', { target: 'overlay' })
    },
    /**
     * payload: 筛选参数
     * from: 触发方式。default: 默认选中
     */
    clickMenuItem ({ payload, index, from }) {
      this.resetArrow()
      const _from = ['default']
      if (!_from.includes(from)) {
        this.filterChange({ ...payload, index })
      }

      if (!this.keepHighlight) return
      
      this.handleHighlightBar({ curIndex: index, curBarHighlight: true })
    },
    // 重置默认 箭头 和 当前点击开的panel的index
    resetArrow () {
      this.$refs?.navBar?.resetArrow()
    },
    recordSelectMenu (panelIndex) {
      this.$refs.navBar.saveIndex(panelIndex)
    },
    /**
     * SingleSelect组件，选择项变化
     * payload 数据
     */
    singleSelectChange (payload) {
      const { data, from, index } = payload
      const { value } = data
      // url回显或者调用方主动调用筛选时无需透传出去
      const _from = ['url', 'propsCalled']
      if (!_from.includes(from)) {
        this.filterChange(payload)
      }

      if (!this.keepHighlight) return

      this.handleHighlightBar({ curIndex: index, curBarHighlight: !!value })
    },
    /**
     * multiSelect组件，选择项变化
     * data 选中的筛选项
     */
    multiSelectChange (payload) {
      const { from } = payload

      const _from = ['url', 'propsCalled']
      if (!_from.includes(from)) {
        this.filterChange(payload)
      }
    },
    clickDone () {
      this.resetArrow()
      this.$emit('closePanel', { target: 'done' })
    },
    clickSideFilterCloseIcon () {
      this.resetArrow()
    },
    sidePanelChange (payload) {
      const { type, index } = payload
      const highlightParams = { curIndex: index }
      const curBarHighlight = this.isProductList ? Boolean(this.filterNum) : Boolean(this.filterBadgeNum)
      if (type == 'single') {
        Object.assign(highlightParams, { curBarHighlight })
      } else if (type == 'multi' || type == 'img') {
        Object.assign(highlightParams, { curBarHighlight })
      } else if (type == 'price') {
        Object.assign(highlightParams, { curBarHighlight })
      }
      this.filterChange(payload)

      if (!this.keepHighlight) return

      this.handleHighlightBar(highlightParams)

      // 侧边filter 点击item，由于item更新 需重新赋值tabindex
      filterAda.slideFilterInit()
    },
    /**
     * 左侧弹出面板点击reset
     * menuItems 每项内容
     * propsCalled 是否是外部主动调用重置
     *  */
    resetAll ({ operation, index, menuItems, outClick }) {
      this.resetDefault(menuItems)
      if (!outClick) {
        this.filterChange({ operation, index })
      }
    },
    // 恢复默认不选中状态
    resetDefault () {
      this.handleHighlightBar({ reset: true })
    },
    setFilterBadgeNum (num) {
      this.filterBadgeNum = num
    },
    // 联动的navBar高亮, 比如侧边选了分类，外露的分类，需要调用此方法去高亮NavBar
    needHighlightNavBar ({ index, highlight }) {
      this.handleHighlightBar({ curIndex: index, curBarHighlight: highlight })
    },
    /**
     * 高亮控制
     * curIndex 当前操作bar的角标
     * curBarHighlight 当前操作的bar
     */
    handleHighlightBar ({ curBarHighlight, curIndex, reset }) {
      this.$refs.navBar.controlHighlight({ curBarHighlight, curIndex, reset })
    },
  }
}
</script>

<style lang="less">
  .filter-ctn {
    width: 100%;
    transition: all .2s ease-out;
    
    &__scrollTop {
      opacity: 0;
      transition: 'all .3s';
      transform: translate3d(0,-2rem,0);
    }
  }
</style>
