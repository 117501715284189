<!-- <template>
  <div
    :class="[
      'nav-bar__item',
      {
        'nav-bar__item_seperator' : item.hasSeperator,
        'nav-bar__item_disable': item.disable
      },
    ]"
    :style="styles"
    tabindex="0"
    :aria-label="item.text"
    role="tab"
    @click="onClick"
    @focus="$emit('navFocus', $event)"
  >
    <span
      class="nav-bar__item_text"
      :class="{
        'nav-bar__item_highlight' : (item.hasCountBadge ? item.panelContentSelected : (item.panelContentSelected || item.active)),
        'nav-bar__item_gap': item.icons
      }"
      :style="item.styles"
    >
      {{ item.text }}

      // icon配置
      <template v-if="item.icons">
        <i
          :class="[
            (constantData.is_include_critical_css && !item.active) ? (item.icons.isSuiIcon ? 'suiiconfont-critical' : 'iconfont-critical') : (item.icons.isSuiIcon ? 'suiiconfont' : 'iconfont'),
            item.active ? (item.icons.active || item.icons.normal) : item.icons.normal
          ]"
        ></i>
      </template>
    </span>
  </div>
</template> -->

<script lang="jsx">

export default {
  name: 'NavBarSort',
  inject: ['constantData'],
  functional: true,
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    // 样式集合
    styles: {
      type: Object,
      default: () => ({
        navItem: {},
        navBar: {}
      })
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick () {
      const item = this.item
      if (item.disable) return
      // 激活高亮和icon
      this.$set(item, 'active', !item.active)
      this.$emit('click', { ...item })
    },
  },
  render(_, { props, injections, listeners }) {
    const { item = {}, styles = { navItem: {}, navBar: {} }, active } = props || {}
    const { constantData = {} } = injections
    const navBarItemClassList = [
      'nav-bar__item',
      {
        'nav-bar__item_seperator': item.hasSeperator,
        'nav-bar__item_disable': item.disable
      },
    ]
    const navBarItemTextClassList = [
      'nav-bar__item_text',
      {
        'nav-bar__item_highlight': (item.hasCountBadge ? item.panelContentSelected : (item.panelContentSelected || active)),
        'nav-bar__item_gap': item.icons
      }
    ]
    const iconClassList = [
      (constantData.is_include_critical_css && !active) ? (item.icons.isSuiIcon ? 'suiiconfont-critical' : 'iconfont-critical') : (item.icons.isSuiIcon ? 'suiiconfont' : 'iconfont'),
      active ? (item.icons.active || item.icons.normal) : item.icons.normal
    ]
    return (
      <div
        class={navBarItemClassList}
        style={styles}
        tabindex='0'
        role='tab'
        vOn:click={function handleClickNavBarSort() {
          if (item.disable) return
          listeners['click']({ ...item })
        }}
      >
        <span
          class={navBarItemTextClassList}
          style={item.styles}
        >
          { item.label || item.label_en || item.text }

          {
            // icon配置
            item.icons ? (<i class={iconClassList} ></i>) : null
          }
        </span>
      </div>
    )
  }
}
</script>
